import { invariant } from 'utils/invariant'

export const CF_URL: string = process.env.NEXT_PUBLIC_CF_URL || ''
export const SHOW_AUTH_MODAL: boolean = process.env.NEXT_PUBLIC_SHOW_AUTH_MODAL === 'true'
export const ASK_CF_URL: string = process.env.NEXT_PUBLIC_ASK_CF_URL || ''
export const CF_FACEBOOK_ID: string = process.env.NEXT_PUBLIC_CF_FACEBOOK_ID || ''
export const VERCEL_ENV: string = process.env.NEXT_PUBLIC_VERCEL_ENV || ''
export const LFS_URL = process.env.NEXT_PUBLIC_LFS_URL
export const ASK_GENIE_USER_ID = process.env.NEXT_PUBLIC_ASK_GENIE_ID

// Feature flags
export const ASK_CF_FF_FOLLOW: boolean = process.env.NEXT_PUBLIC_ASK_CF_FF_FOLLOW === 'true'
export const ASK_CF_FF_FEED_ADS: boolean = process.env.NEXT_PUBLIC_ASK_CF_FF_FEED_ADS === 'true'
export const ASK_CF_FF_ANSWER_LIKES: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_ANSWER_LIKES === 'true'
export const ASK_CF_FF_ANSWER_TIPS: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_ANSWER_TIPS === 'true'
export const ASK_CF_FF_COMMENTS: boolean = process.env.NEXT_PUBLIC_ASK_CF_FF_COMMENTS === 'true'
export const ASK_CF_FF_ADD_ANSWER: boolean = process.env.NEXT_PUBLIC_ASK_CF_FF_ADD_ANSWER === 'true'
export const ASK_CF_FF_BADGES: boolean = process.env.NEXT_PUBLIC_ASK_CF_FF_BADGES === 'true'
export const ASK_CF_FF_LEADERBOARD: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_LEADERBOARD === 'true'
export const ASK_CF_FF_SUPERSKILL: boolean = process.env.NEXT_PUBLIC_ASK_CF_FF_SUPERSKILL === 'true'
export const ASK_CF_FF_YOUR_QUESTIONS: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_YOUR_QUESTIONS === 'true'
export const ASK_CF_FF_YOUR_ANSWERS: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_YOUR_ANSWERS === 'true'
export const ASK_CF_FF_QUESTIONS_TO_ANSWER: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_QUESTIONS_TO_ANSWER === 'true'
export const ASK_CF_FF_QUESTIONS_RECOMMENDED: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_QUESTIONS_RECOMMENDED === 'true'
export const ASK_CF_FF_NOTIFICATIONS: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_NOTIFICATIONS === 'true'
export const ASK_CF_FF_FEED_SORT: boolean = process.env.NEXT_PUBLIC_ASK_CF_FF_FEED_SORT === 'true'
export const ASK_CF_FF_FEED_SORT_UNANSWERED: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_FEED_SORT_UNANSWERED === 'true'
export const ASK_CF_FF_YOUR_QUESTIONS_COUNT: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_YOUR_QUESTIONS_COUNT === 'true'
export const ASK_CF_FF_BEST_ANSWERS: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_BEST_ANSWERS === 'true'
export const ASK_CF_FF_LANGUAGE_SWITCHER: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_LANGUAGE_SWITCHER === 'true'
export const ASK_CF_FF_COMMENT_PHOTO: boolean =
  process.env.NEXT_PUBLIC_ASK_CF_FF_COMMENT_PHOTO === 'true'

export const IS_PROD_ENV: boolean = VERCEL_ENV === 'production'

invariant(CF_URL, 'NEXT_PUBLIC_CF_URL is required within apps/ask-cf')
invariant(ASK_CF_URL, 'NEXT_PUBLIC_ASK_CF_URL is required within apps/ask-cf')
invariant(CF_FACEBOOK_ID, 'NEXT_PUBLIC_CF_FACEBOOK_ID is required within apps/ask-cf')
invariant(LFS_URL, 'NEXT_PUBLIC_LFS_URL is required within apps/ask-cf')
